export default function () {
  controller('base-slider', self => {
    
    var flag = false;

    function init() {

      if (window.innerWidth > 767) {

        if (!flag) {

          self.slick({
            mobileFirst: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendArrows: $(".js-base-nav"),
            appendDots: $(".js-base-nav"),
            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              }
            ]
          });

          flag = true;
        }
      } else {

        if (flag) {

          self.slick('destroy');

          flag = false;
        }
      }
    }

    init();
    $(window).on('resize', init);
    
  });
}
