export default function () {
	controller('subscribe-toggle', self => {
		console.log('controller subscribe-toggle');

		$('[data-subscribe-toggle-click]').on('click', function(event) {
			console.log('data-subscribe-toggle-click click', [event, event.currentTarget, self]);
			flip();
		});


		function flip() {
			$('[data-selective-hide]').map((index, toggleItem) => {
				const $toggleItem = $(toggleItem);
				console.log('data selectiveHide',[$toggleItem.data('selectiveHide')]);
				if ('hide' == $toggleItem.data('selectiveHide')) {
					$toggleItem.data('selectiveHide', 'show');
					$toggleItem.hide();
					console.log('subscribe-toggle hide');
				} else {
					$toggleItem.data('selectiveHide', 'hide');
					$toggleItem.show();
					console.log('subscribe-toggle show');
				}
			});
		}

	});
}
