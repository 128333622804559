export default function () {
  controller('mobile-menu', self => {
    self.on('click', function() {
      if ( window.innerWidth < 768 ) {
        
        $('.js-mobile-nav').addClass('show');
        $('body').css({
          'overflow': 'hidden'
        })
      }
    });
    
    $('.js-mobile-nav-close').on('click', function() {
      if ( window.innerWidth < 768 ) {

        $('.js-mobile-nav').removeClass('show');
        $('body').removeAttr('style');
      }
    });
    
    $(window).on('resize', function() {
      if ( window.innerWidth > 768 ) {
        $('.js-mobile-nav').removeClass('show');
        $('body').removeAttr('style');
      }
    });
  });
}
