export default function () {
  controller('text-cut', self => {
    
    function init() {

      if ( window.innerWidth > 767 && window.innerWidth < 1024 ) {
        self.shave('66');
      } else {
        self.shave('44');
      }
    }
    
    $(window).ready(init);
    $(window).on('resize', init);
    
  });
}
