import CONSTANTS from './constants'
import browserUpdate from 'browser-update';
// import preloader from './controller/preloader';
import mobileMenu from "./controller/mobileMenu";
import textCut from "./controller/textCut";
import textCutLarge from "./controller/textCutLarge";
import baseSlider from "./controller/baseSlider";
import baseTop from "./controller/baseTop";
import loadSpinner from './controller/loadSpinner';
import subscribeToggle from './controller/subscribeToggle';
import subscribe from './subscribe';

window.controller = function(ctrl, cb) {
  var controllerContainer = $('[data-controller="' + ctrl + '"]');

  if (controllerContainer.length) {
    cb(controllerContainer);
  } else {
    return undefined
  }
}

// preloader();
browserUpdate(CONSTANTS.browserUpdateOptions);

$(document).ready(() => {
  svg4everybody(); //SVG polyfill
  mobileMenu();
  // textCut();
  // textCutLarge();
  baseSlider();
  baseTop();
  subscribeToggle();
  // loadSpinner();
  subscribe();
  //Other controllers and js modules here
});
