export default function () {
  controller('base-top', self => {
    function baseTopSlider() {
      var flag = false;

      function init() {

        if (window.innerWidth < 768) {

          if (!flag) {

            $('.js-base-mobile-slider').slick({
              mobileFirst: true,
              dots: true,
              slidesToScroll: 1,
              appendArrows: $(".js-base-nav-mobile"),
              appendDots: $(".js-base-nav-mobile"),
              responsive: [
                {
                  breakpoint: 639,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
              ]
            });

            flag = true;
          }
        } else {

          if (flag) {

            $('.js-base-mobile-slider').slick('destroy');

            flag = false;
          }
        }
      }

      init();
      $(window).on('resize', init);
 
    }
    
    baseTopSlider();
    
    function baseTopCollapse() {
      
      $('.js-base-top-btn').on('click', function() {
        
        $('.js-base-top-collapse').addClass('active');
      });

      $('.js-base-top-close').on('click', function() {

        $('.js-base-top-collapse').removeClass('active');
      });
    }
    
    baseTopCollapse();

  });
}
