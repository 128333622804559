export default function () {

	$('.js_subscribe_form_btn').on('click', async function(event) {
		event.preventDefault();
		const form = $(event.currentTarget).parents('.js_subscribe_form');
		const url = form.attr('action');
		const email = form.find("input[name='email']").first().val();

		try {
			const result = await sendEmail(url, email);
			if (undefined !== result && undefined !== result.result) {
				if (true === result.result) {
					toastr.info('Ваш email успешно добавлен');
					form.find("input[name='email']").first().val('');
				} else {
					toastr.error('Ошибка запроса: ' + result.errors);
				}
			}
		} catch(e) {
			toastr.error('Ошибка запроса: ' + e.responseText + '');
		}
	});

	async function sendEmail(url, email) {
		return await $.ajax({
			url: url,
			method: 'POST',
			data: {email: email}
		});
	}

}
