export default function () {
  controller('text-cut-large', self => {

    function init() {
      
      if ( window.innerWidth < 639 ) {
        self.shave('110');
      } else if ( window.innerWidth > 639 && window.innerWidth < 767 ) {
        self.shave('130');
      } else if ( window.innerWidth > 767 && window.innerWidth < 1023 ) {
        self.shave('170');
      } else {
        self.shave('180');
      }
    }
    
    $(window).ready(init);
    $(window).on('resize', init);

  });
}
